<template>
  <v-card flat>
    <v-toolbar flat dense class="grey lighten-5">
      <v-btn icon @click="$router.go(-1)">
        <v-icon>mdi-arrow-left</v-icon>
      </v-btn>
      <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <v-toolbar-title class="py-3 px-0">
        <span>
          {{ $t("user.ac") }}
        </span>
        <v-icon v-show="changed" color="primary">mdi-textbox-password</v-icon>
      </v-toolbar-title>
      <v-spacer />
      <v-chip class="px-2" small label v-if="isReadOnly" color="yellow">
        <v-avatar left>
          <v-icon small>mdi-alert</v-icon>
        </v-avatar>
        {{ $t("general.readonly") }}
      </v-chip>
      <v-divider vertical inset class="d-none d-md-flex mx-2" />
      <v-btn
        :disabled="isReadOnly"
        small
        text
        color="success"
        class="mx-2"
        @click="save"
      >
        <v-icon>mdi-content-save</v-icon>
        <span class="text--secondary">{{ $t("general.save") }}</span>
      </v-btn>
    </v-toolbar>

    <v-card-text class="overflow-y-auto px-0 py-0" :style="viewHeight(102)">
      <v-form ref="form" class="py-5 px-5">
        <v-list dense>
          <v-list-item-group color="primary">
            <div v-for="(r, i) in getRoles" :key="i">
              <v-divider v-if="i !== 0" />
              <v-list-item :disabled="(r.name === 'Administrator' && !$hasRole('Administrator')) || (r.name === 'Resource Manager' && (!$hasRole('Administrator') || !$hasRole('Resource Manager')))">
                <v-list-item-content>
                  <v-list-item-title class="text-uppercase">
                    {{ r.name }}
                  </v-list-item-title>
                  <v-list-item-subtitle>
                    {{ r.description }}
                  </v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-switch
                    :readonly="isReadOnly"
                    v-model="selectedRoles"
                    :value="r.name"
                  />
                </v-list-item-action>
              </v-list-item>
            </div>
          </v-list-item-group>
        </v-list>
        <br />
        <v-divider />
        <v-btn
          :disabled="isReadOnly"
          :dark="!isReadOnly"
          color="success"
          class="mr-4"
          tile
          depressed
          @click="save"
        >
          <v-icon>mdi-content-save</v-icon>
          {{ $t("general.save") }}
        </v-btn>

        <v-btn
          :disabled="isReadOnly"
          color="primary"
          tile
          depressed
          @click="reset"
        >
          <v-icon>mdi-undo-variant</v-icon>
          {{ $t("general.undo") }}
        </v-btn>
      </v-form>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "UserRoles",
  props: {
    item: {
      type: Object,
      required: true,
      default() {
        return {
          id: 0,
          roles: [],
        };
      },
    },
  },
  watch: {
    item: {
      handler: function(n) {
        if (n) {
          this.selectedRoles = n.roles;
        }
      },
    },
  },
  data() {
    return {
      selectedRoles: [],
      originalState: { id: 0 },
    };
  },
  mounted() {
    Object.assign(this.originalState, this.item);
  },
  computed: {
    ...mapGetters("roles", ["getRoles"]),


    changed() {
      return this.item && !this.compareObjects(this.item, this.originalState);
    },
    idParam() {
      return parseInt(this.$route.params.id);
    },
  },
  methods: {
    save() {
      this.$emit("save", {
        id: this.idParam,
        assignments: this.selectedRoles,
        keepOpen: this.keepOpen,
      });
    },
    reset() {
      Object.assign(this.item, this.originalState);
    },
  },
};
</script>
