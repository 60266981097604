<template>
  <v-container class="white py-0 px-0" fluid>
    <v-card flat>
      <user-form
        v-if="$route.name === 'user'"
        :item="item"
        @save="save"
      />

      <user-roles
        v-if="$route.name === 'user.roles'"
        :item="item"
        @save="save"
      />

      <user-hubs
        v-if="$route.name === 'user.hubs'"
        :item="item"
        @save="save"
      />
    </v-card>
  </v-container>
</template>

<script>
import UserForm from "./UserForm";
import UserRoles from "./UserRoles";
import UserHubs from "./UserHubs";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "UserEditor",
  components: { UserForm, UserRoles, UserHubs },
  data() {
    return {
      item: {
        // id: 0,
        // username: "",
        // firstName: "",
        // lastName: "",
        // email: "",
        // avatar: null,
        // enabled: false,
        organizationId: parseInt(this.$route.params.org),
        // skipConfirmEmail: false,
        // updatePassword: false,
        // password: "",
        // confirmPassword: "",
      },
    };
  },
  computed: {
    ...mapGetters("users", ["getUserById", "getUserError"]),

    idParam() {
      return parseInt(this.$route.params.id);
    },
    orgIdParam() {
      return parseInt(this.$route.params.org);
    },
    editMode() {
      return this.idParam > 0;
    },
  },
  mounted() {
    this.loadOrganizations();

    // in case user navigated directly to edit view load target item only
    if (this.editMode) {
      this.setLoadingState(this.$t('messages.loadingUser'));
      const item = this.getUserById(this.idParam);
      if (item) {
        this.item = item;
        this.refreshBreadcrumb();
      } else {
        this.loadUsers({ orgId: this.orgIdParam }).then(() => {
          this.item = this.getUserById(this.idParam);
          this.refreshBreadcrumb();
        });
      }
    } else {
      this.setLoadingState(this.$t('messages.initializingUser'));
      this.refreshBreadcrumb();
    }
  },
  methods: {
    ...mapActions("organizations", ["loadOrganizations", "saveOrganization"]),
    ...mapActions("users", ["loadUsers", "saveUser", "saveUserRoles", "saveUserHubs", "setUserError"]),
    ...mapActions("ui", ["setBreadcrumbs", "setLoadingState", "clearLoadingState"]),

    refreshBreadcrumb() {
      const updatedBreadcrumbs = this.$route.meta.breadcrumb.map((item) => {
        if (item.dynamic && this.editMode && this.item) {
          return { ...item, name: this.item.username, skipTranslate: true };
        }
        return item;
      });
      this.setBreadcrumbs(updatedBreadcrumbs);
      this.clearLoadingState();
    },
    save(params) {
      if (this.$route.name === "user") {
        const { item, editMode } = params;
        this.saveUser({ item, editMode });
        if (this.getUserError !== "conflict" && !params.keepOpen) {
          this.$router.go(-1);
        }
        this.setUserError(null);
      } else if (this.$route.name === "user.roles") {
        const { id, assignments } = params;
        this.saveUserRoles({ id, assignments });
        this.$router.go(-1);
      } else if (this.$route.name === "user.hubs") {
        const { id, assignments } = params;
        this.saveUserHubs({ id, assignments });
        this.$router.go(-1);
      }
    },
  },
};
</script>
